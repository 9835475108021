import React, { useEffect, useState } from "react";
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby";
import { Container, Row, Col, Tabs, Tab, Nav } from "react-bootstrap";
import Slider from "react-slick";
import ScrollAnimation from 'react-animate-on-scroll';
import NoImg from '../../../images/no-image.png';
import Btns from "../../Revamp/Buttons/Buttons"
import PlayVideo from '../../../components/Play/PlayVideo';
import { youtube_parser, CustomLinks } from "../../common/utils";
import { ImageModule } from "../../../modules/Image_Module";
import { FeatureInsights } from "../../../queries/common_use_query";
import "./News.scss";
import HTMLReactParser from 'html-react-parser';


// Header component

const News = (props) => {
    const [isPlay, setPlay] = useState(false);
    const [videoId, setVideoId] = useState('');
    const [tabKey, setTabKey] = useState(props.Module?.Tab_List?.length > 0 ? props.Module?.Tab_List[0].id : '0');
    const [filterCategory, setFilterCat] = useState(props.Module?.Tab_List?.length > 0 ? props.Module.Tab_List[0].Tab_Name : '');
    const [newsList, setNewsList] = useState([]);

    const filterTab = (id, API_Filter_Value) => {
        setTabKey(id);
        setFilterCat(API_Filter_Value);
    }

    useEffect(() => {

        if (props.Module && props.Module.Tab_List && props.Module.Tab_List.length > 0 && props.Module.Tab_List[0].id) {
            setTabKey(props.Module.Tab_List[0].id);
            setFilterCat(props.Module.Tab_List[0].Tab_Name ? props.Module.Tab_List[0].Tab_Name : "");
        }
    }, [props.Module])

    var data = useStaticQuery(graphql`
    query MyQuery {
        glstrapi {
           insights(sort: "createdAt:desc", limit:7, where:{insight_category:{Category_contains:""}}) {      
              id
              Title
              URL
              Published_Date 
              news_and_insights_category: insight_category{
                id
                Name:Category
              }
              Embed_Video_URL
              Image {
                url
                alternativeText
              }
              imagetransforms      
          }
        }
      }             
  `)

    // if page is not home do live stapi grapQl call
    if (props.Alias !== "home") {
        var { loading, error, data } = FeatureInsights("");
    }

    useEffect(() => {
        //  if page is home get data from glstrapi
        if (props.Alias === "home") {  
            data && data.glstrapi.insights && setNewsList(data.glstrapi.insights);
        } else {
            data && data.insights && setNewsList(data.insights);
        }
    }, [data, props.page_alias])


    const settings = {
        dots: false,
        speed: 3000,
        slidesToShow: 4,
        slidesToScroll: 3,
        infinite: false,
        arrows: false,
        mobileFirst: true,
        pauseOnHover: false,
        autoplay: true,
        autoplaySpeed: 8000,
        dots: true,
        loop: false,
        responsive: [
            {
                breakpoint: 1300,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: false,
                },
            },
            {
                breakpoint: 991,
                settings: {
                    dots: true,
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    infinite: false,
                },
            }, 
           
            {
                breakpoint: 767,
                settings: {
                    dots: true,
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    var setting_val2 = { ...settings, infinite: newsList && newsList.length > 3 ? true : false };

   // console.log("videoIdvideoId", props.Module?.Tab_List); 
    return (
        <React.Fragment>
            <ScrollAnimation animateIn='fadeInUp' animateOnce={true}>

                <section className={`news-section ${props.Module && props.Module.Section_BG_Color} ${props.Module.NewsVersion}`}>
                    <Container>
                        <Row>
                            <Col className="news-content">
                                <div className="news-heading">
                                    <h2>{props.CustomTitle ? props.CustomTitle : (props.Module && props.Module.Title) ? props.Module.Title : props.Title}</h2>
                                    {props.Module && props.Module.Content && HTMLReactParser(props.Module.Content)}
                                </div>

                                {
                                    tabKey &&
                                    <Tab.Container defaultActiveKey={tabKey}>
                                        {
                                            <Nav variant="tabs" className={props?.page?.id == "617006e30eca257d6a9947f8" ? "nav-tab2" : ""}>
                                                {
                                                    props.Module?.Tab_List && props.Module.Tab_List.length > 0 && props.Module.Tab_List.map((item, index) => {
                                                        return (
                                                            <Nav.Link href="javascript:;" eventKey={item.id} onClick={() => filterTab(item.id, item.Tab_Name)}>{item.Tab_Name}</Nav.Link>
                                                        )
                                                    })
                                                }
                                            </Nav>
                                        }

                                        <Tab.Content>
                                            {
                                                props.Module?.Tab_List && props?.Module?.Tab_List?.length > 0 && props.Module?.Tab_List?.map((tab, i) => {
                                                    var setting_val = { ...settings, infinite: tab?.insights && tab?.insights?.length > 3 ? true : false };
                                                    return (
                                                        <Tab.Pane eventKey={tab.id}>
                                                            {
                                                                <Slider {...setting_val}
                                                                className={props.Module.NewsVersion == "revamp"? "custom-dots" : ""}
                                                                >
                                                                    {
                                                                        tab.insights.length > 0 && tab.insights.map((item, index) => {
                                                                            var youtube_id = item.Embed_Video_URL && item.Embed_Video_URL && youtube_parser(item.Embed_Video_URL);
                                                                            return (
                                                                                <div className="news-wrapper">

                                                                                    <div className="news-img img-zoom">
                                                                                        <Link to={`${CustomLinks.insights}/${item.URL}/`}>
                                                                                            <picture>
                                                                                                {item.Image ?
                                                                                                    <ImageModule ImageSrc={item.Image}
                                                                                                        altText={item.Name + " - Strettons"}
                                                                                                        ggfx_results={item.ggfx_results}
                                                                                                        imagename="news-and-insights.Image.small_image" strapi_id={item.id} />
                                                                                                    : <img src={NoImg} alt={item.Name} />
                                                                                                }
                                                                                            </picture>
                                                                                        </Link>
                                                                                        {
                                                                                            item.Embed_Video_URL &&
                                                                                            <div className="video-buttons" onClick={(e) => { setVideoId(youtube_id); setPlay(true) }}>
                                                                                                <strong className="video-btn">
                                                                                                    <i className="icon-video-black"></i>
                                                                                                </strong>
                                                                                            </div>
                                                                                        }

                                                                                        {/* {item.Service && <div className="sm-title">{item.Service}</div>} */}
                                                                                    </div>

                                                                                    <div className="news-details">
                                                                                        {/* <h5>
                                                                                    {dateFormat(item.Published_Date, "mmmm dd yyyy")} / {item.news_and_insights_category.Name}
                                                                                </h5> */}
                                                                                        {props.Module.NewsVersion != "revamp" &&
                                                                                            <h5>
                                                                                                {item.news_and_insights_category?.Name}
                                                                                            </h5>
                                                                                        }
                                                                                        <h2>
                                                                                            <Link to={`${CustomLinks.insights}/${item.URL}/`}>
                                                                                                {item.Title}
                                                                                            </Link>
                                                                                        </h2>
                                                                                        {props.Module.NewsVersion == "revamp" &&
                                                                                            <h5>
                                                                                                {item.news_and_insights_category?.Name}
                                                                                            </h5>
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </Slider>
                                                            }
                                                        </Tab.Pane>
                                                    )
                                                })
                                            }
                                        </Tab.Content>

                                    </Tab.Container>
                                }
                                {props.Module.NewsVersion == "revamp" &&
                                    <Btns btns={[
                                        {
                                            Label: "View More",
                                            Custom_Link: "/insights"
                                        }
                                    ]} />
                                }

                            </Col>
                        </Row>
                    </Container>
                </section>

            </ScrollAnimation>

            <PlayVideo
                isOpen={isPlay}
                isCloseFunction={setPlay}
                videoId={videoId}
                isAutoPlay={1}
            />
        </React.Fragment>
    )
}
export default News
