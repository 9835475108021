import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import { Container, Row, Button } from "react-bootstrap"
import {menuLinks} from "../../common/pageUtils";
import "./Buttons.scss"

const Buttons = ({ btns }) => {
    return (
        <React.Fragment>
            {btns && btns.length > 0 &&
                <div className="revamp-btns">
                    {btns.map((btn, k) => {
                        return (
                            <div className="button" key={k}>
                                {btn.Custom_Link ?
                                    <Link to={(btn.Custom_Link)} className={`btn btn-primary ${btn.Classname}`}>{btn.Label}   <i className="arrow-right"></i></Link>
                                    :
                                    <Link to={menuLinks(btn.Link)} className={`btn btn-primary ${btn.Classname}`}>{btn.Label}   <i className="arrow-right"></i></Link>
                                } 
                            </div>
                        )
                    })}
                </div>
            } 
        </React.Fragment>


    );
};
export default Buttons;
