import React from "react"
import Peoples from "../RightSideComponent/Sections/Peoples"
import SubscribeSection from "../RightSideComponent/Sections/SubscribeSection"

const RightSide = (props) => {
  return (
    <div className="management-contact insight_details" >
      { props.details && props.details.people && <Peoples Data={[props.details.people]}/>}
      { props.details.Subscribe && <SubscribeSection isFooter={false} /> }
    </div>
  );
};
export default RightSide;